@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

.highlightOption, .chip, .multiSelectContainer li:hover {
  background-color: #056E72 !important;
}

.searchWrapper{
  border: none !important
}


@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  } 
  ul{
    @apply list-disc
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.parts-table td, th{
  padding: 4px 0px 4px 0px;
}

.slick-prev {
  left: 10px;
  /* z-index: 10; */
}

.slick-next {
  right: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.navbar li{
  margin: 0px !important;
}



@media screen and (max-width: 426px) {
  .captcha{
    transform: scale(0.8) translateX(-12%);
  }
}
@media screen and (max-width: 334px) {
  .captcha{
    transform: scale(0.75) translateX(-18%);
  }
}

.ql-font-arial {
  font-family: 'Arial', sans-serif;
}


.ql-editor {
   
    padding: 0 !important;
    
}

.parts{
  padding-left: 0.25rem !important;
}